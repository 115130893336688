.App {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #83E4C6;
}

.logo {
  width: 50vw;
}
